import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import Routing from './routes';
import '../src/assets/css/aos.css'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/icofont.min.css'
import '../src/assets/css/magnific-popup.min.css'
import '../src/assets/css/responsive.css'
import '../src/assets/css/style.css'
import ScrollToTop from "./ScrollToTop";
import '../src/assets/css/profile-page.css'
import '../src/assets/css/loading.css'
import '../src/assets/css/home.css'
import { AuthProvider } from "./configs/AuthContext";
import ReactPixel from 'react-facebook-pixel';
import {useEffect} from 'react';

// Componente per tracciare le visite alle pagine
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView(location.pathname); // Traccia l'evento PageView
    console.log('PageView tracciato:', location.pathname); // Debug (opzionale)
  }, [location]);

  return null; // Non renderizza nulla
}

export default function App() {
  ReactPixel.init('1711894369659482'); // Sostituisci con il tuo Pixel ID
  ReactPixel.pageView(); // Registra la visualizzazione di una pagina

  return (
    <>
      <AuthProvider>
        <Router >

          <ScrollToTop />
          <PageTracker />
          <Routing />
        </Router>
      </AuthProvider>

    </>
  );
}