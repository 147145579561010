import React from 'react';
//LAYOUT
import Header from "../Header/Main";
import Footer from '../Footer/Main'
import {Link} from "react-router-dom";
import error from '../../assets/images/snapmemories/error.png'
import Mailicon from "../../assets/images/mail_icon.png";
import Phoneicon from "../../assets/images/phone_icon.png";
import whatsappicon from "../../assets/images/snapmemories/whatsapp.png";

export const ResultPage = () => {

    return (

        <>
            <Header/>

            <section className="error-page">
                <div className={"container-card"}>
                    <div style={{padding: "2result-page/success3px"}}>
                        <div className="card" style={{borderRadius: "20px"}}>
                            <div className="form_block" style={{display: 'block'}}>
                                <div className="form_side"
                                     style={{
                                         padding: "15px",
                                         width: "100%",
                                         textAlign: "center"
                                     }}>
                                    <img src={error} alt="image" style={{width: "20vh"}}/>
                                    <div className="section_title" style={{textAlign: "center", marginTop: "30px"}}>
                                        <h2>
                                            Errore durante l'acquisto!
                                        </h2>
                                        <p>Siamo spiacenti, ma non è stato possibile completare l'acquisto!</p>
                                        <br/>
                                        <p>Contattaci per ricevere supporto:</p>

                                    </div>
                                    <ul className="contact_listing">
                                        <li>
                                            <span className="icon">
                                                <img src={Mailicon} alt="image"/>
                                            </span>
                                            <span className="lable">Inviaci una mail</span>
                                            <Link to="mailto:info@maffle.it">info@maffle.it</Link>
                                        </li>
                                        <li>
                                            <span className="icon">
                                                <img src={Phoneicon} alt="image"/>
                                            </span>
                                            <span className="lable">Chiamaci</span>
                                            <Link to="tel:3791785539">+393791785539</Link>
                                        </li>
                                        <li>
                                        <span className="icon">
                                            <img src={whatsappicon} alt="image" style={{
                                                width: '7vh'
                                            }}/>
                                              </span>
                                            <span className="lable">Whatsapp</span>
                                            <Link
                                                to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Avvia
                                                la chat
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='page_wrapper'>
                <Footer/>
            </div>
        </>
    )
}

export default ResultPage;