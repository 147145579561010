import React, { useEffect, useState } from 'react';
// import screen from "../../assets/images/sign_in_screen.png";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import AWS from 'aws-sdk';
import { env } from "../../configs/EnvironmentConfig";
import Loading from "../../shared-components/Loading";
import ReactPixel from 'react-facebook-pixel';
import axios from "axios";


function ConfigureEvent({ checkout, handleTabChange }) {

    const [color, setColor] = useState(checkout?.theme ? checkout?.theme : 'dark');
    const [name, setName] = useState(checkout?.name ? checkout?.name : '');
    const [categoria, setCategoria] = useState(checkout?.categoria ? checkout?.categoria : 'wedding');
    const [messaggioPersonalizzatoSagra, setMessaggioPersonalizzatoSagra] = useState('')
    const [emoticons, setEmoticons] = useState([]);
    const [start, setStart] = useState(checkout?.start ? checkout?.start : '');
    const [end, setEnd] = useState(checkout?.end ? checkout?.end : '');
    const [errors, setErrors] = useState({});

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: 1,
    };

    const onFinish = () => {
        let validationErrors = {};

        if (!name) validationErrors.name = "Il nome dell'evento è obbligatorio.";
        if (!start) validationErrors.start = "La data di inizio è obbligatoria.";
        if (!end) validationErrors.end = "La data di fine è obbligatoria.";

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        ReactPixel.trackCustom('SubmitFormCategoria', {
            categoria: categoria,
            nomeEvento: name,
            startDate: start,
            endDate: end,

        });

        setErrors({});

        let partsStart = start.split("-");
        let partsEnd = end.split("-");
        let formattedDateStart = `${partsStart[2]}/${partsStart[1]}/${partsStart[0]}`;
        let formattedDateEnd = `${partsEnd[2]}/${partsEnd[1]}/${partsEnd[0]}`;

        handleTabChange({ c: true }, {
            name: name,
            theme: color,
            start: formattedDateStart,
            end: formattedDateEnd,
            categoria: categoria,
            messaggioPersonalizzatoSagra: messaggioPersonalizzatoSagra
        }, "event");
    };

    const getMinEndDate = () => {
        if (start) {
            const startDate = new Date(start);
            startDate.setDate(startDate.getDate() + 1);
            return startDate.toISOString().split('T')[0];
        } else {
            const today = new Date();
            today.setDate(today.getDate() + 2);
            return today.toISOString().split('T')[0];
        }
    };

    const getMaxEndDate = () => {
        if (start) {
            const startDate = new Date(start);
            startDate.setDate(startDate.getDate() + 6);
            return startDate.toISOString().split('T')[0];
        } else {
            const today = new Date();
            today.setDate(today.getDate() + 7);
            return today.toISOString().split('T')[0];
        }
    };

    useEffect(() => {
        if (!checkout?.name) {
            setName('')
            setStart('')
            setEnd('')
            setColor('dark')
            setCategoria('wedding')
            fetchData('wedding')
            setMessaggioPersonalizzatoSagra('' +
                '')
            setErrors({});
        }
    }, [checkout])


    const fetchData = async (categoriaChange) => {
        try {
            const response = await axios.get(`${env.API_ENDPOINT_BACKEND}/snapmemories/get-emoticons?categoria=${categoriaChange}`, {});
            console.log("response", response.data.risultato)
            setEmoticons(response.data.risultato)
        }catch (e) {
            console.error(e)
        }
    };

    return (
        <>
            <section className="row_am interface_section" style={{ display: "flex", paddingTop: "0" }}>
                <div className="container">
                    <div className="blog_listings">
                        <div style={{
                            padding: isMobile ? "30px 30px 10vh" : "30px 80px 10vh",
                            borderRadius: "20px",
                            backgroundColor: "white"
                        }}
                            data-aos="fade-up"
                            data-aos-duration={1500}>
                            <div className="authore_info"
                                style={{
                                    width: "auto",
                                    margin: "auto",
                                    marginBottom: "50px"
                                }}>
                                <div className="section_title" data-aos="fade-up" data-aos-duration={1500}
                                    data-aos-delay={300}>
                                    <span className="title_badge">Step numero 2</span>
                                </div>
                                <div className="form_block">
                                    <div className="form_side">
                                        <form>
                                            <div className="form-group-acquisto">
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: isMobile ? "baseline" : "center",
                                                    marginTop: "20px",
                                                    marginBottom: errors.name ? "0px" : "20px",
                                                    justifyContent: "center",
                                                    flexDirection: isMobile ? "column" : "row",
                                                }}>
                                                    <div
                                                        style={{
                                                            minWidth: !isMobile ? "280px" : "none"
                                                        }}> Categoria dell'evento:
                                                    </div>
                                                    <select
                                                        className="form-control"
                                                        id="categoria"
                                                        name="categoria"
                                                        onChange={(e) => {
                                                            setCategoria(e.target.value)
                                                            fetchData(e.target.value)
                                                        }}
                                                        defaultValue={categoria}
                                                        value={categoria}
                                                    >
                                                        <option value="0" disabled>Seleziona una categoria per
                                                            l'evento
                                                        </option>
                                                        <option value="wedding">Matrimonio</option>
                                                        {/* <option value="vacanza-mare">Vacanza al mare</option> */}
                                                        <option value="compleanno">Compleanno</option>
                                                        <option value="sagra-paese">Festa di paese</option>
                                                        {/*<option value="azienda">Evento aziendale</option>*/}
                                                    </select>
                                                </div>

                                                {emoticons.length === 0 ?
                                                    <Loading />
                                                    :

                                                    <div style={{
                                                        minHeight: "120px",
                                                    }}>
                                                        {categoria === 'sagra-paese' ?

                                                            <>
                                                                <div style={{
                                                                    textAlign: "center"
                                                                }}>
                                                                    Emoticons di esempio: <br />
                                                                    <br />

                                                                    {emoticons.map(emoticon =>
                                                                        <img
                                                                            style={{
                                                                                width: "50px",
                                                                                margin: "5px"
                                                                            }}
                                                                            src={env.URL_CDN + 'emoticons/' + categoria + '/' + emoticon} />
                                                                    )
                                                                    }
                                                                    <br />
                                                                    <br />
                                                                    <strong>
                                                                        Le emoticons per questa categoria sono
                                                                        completamente
                                                                        personalizzabili!
                                                                    </strong><br />
                                                                    Descrivi il tema del tuo evento e troveremo per te
                                                                    le emoticons più significative.
                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: isMobile ? "baseline" : "center",
                                                                    marginTop: "20px",
                                                                    marginBottom: errors.name ? "0px" : "20px",
                                                                    justifyContent: "center",
                                                                    flexDirection: isMobile ? "column" : "row",
                                                                }}>
                                                                    <div
                                                                        style={{
                                                                            minWidth: !isMobile ? "280px" : "none"
                                                                        }}> Descrizione dell'evento:
                                                                    </div>
                                                                    <textarea
                                                                        style={{
                                                                            height: "120px"
                                                                        }}
                                                                        className="form-control"
                                                                        id="messaggioPersonalizzatoSagra"
                                                                        name="messaggioPersonalizzatoSagra"
                                                                        placeholder={"Es. Nel Palio del Ciuccio di Santa Maria la Carità le cinque contrade cittadine, Aquila, Delfino, Farfalla, Ippocampo e Leone " +
                                                                            "si contenderanno il titolo vincitore del Torneo delle Contrade!"}
                                                                        onChange={(e) => setMessaggioPersonalizzatoSagra(e.target.value)}
                                                                        defaultValue={messaggioPersonalizzatoSagra}
                                                                        value={messaggioPersonalizzatoSagra}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </>
                                                            :
                                                            <div style={{
                                                                textAlign: "center"
                                                            }}>
                                                                Emoticons disponibili per la categoria
                                                                selezionata: <br />

                                                                {emoticons.map(emoticon =>
                                                                    <img
                                                                        style={{
                                                                            width: "50px",
                                                                            margin: "5px"
                                                                        }}
                                                                        src={env.URL_CDN + 'emoticons/' + categoria + '/' + emoticon} />
                                                                )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }


                                                <div>
                                                    {checkout?.piano === "basic" ?
                                                        <div style={{
                                                            textAlign: "center"
                                                        }}>
                                                            Il pacchetto selezionato non prevede la
                                                            personalizzazione del tema. <br />
                                                            Il colore base che sarù utilizzato per l'evento
                                                            è <strong>Blu</strong>. <br />
                                                            Passa ad un piano "<strong>Medium</strong>" o
                                                            "<strong>Premium</strong>" per abilitare
                                                            questa funzione.
                                                        </div>

                                                        :

                                                        <div className="btn-group btn-group-toggle"
                                                            data-toggle="buttons">
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                marginTop: "20px",
                                                                marginBottom: "20px",
                                                                flexDirection: isMobile ? "column" : "row",
                                                            }}>
                                                                <div style={{
                                                                    minWidth: !isMobile ? "280px" : "none"
                                                                }}> Seleziona il tema:
                                                                </div>
                                                                <div>
                                                                    <label onClick={() => setColor('dark')}
                                                                        className={"btn btn-blue " + (color === 'dark' ? 'active' : '')}
                                                                        style={{
                                                                            borderRadius: "30px",
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            margin: 5,
                                                                        }}
                                                                        defaultChecked={color === 'dark'}
                                                                    >
                                                                    </label>
                                                                    <label onClick={() => setColor('glass')}
                                                                        className={"btn btn-green " + (color === 'glass' ? 'active' : '')}
                                                                        style={{
                                                                            borderRadius: "30px",
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            margin: 5,
                                                                        }}
                                                                        defaultChecked={color === 'glass'}
                                                                    >
                                                                    </label>
                                                                    <label onClick={() => setColor('light')}
                                                                        className={"btn btn-light " + (color === 'light' ? 'active' : '')}
                                                                        style={{
                                                                            borderRadius: "30px",
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            margin: 5,
                                                                        }}
                                                                        defaultChecked={color === 'light'}
                                                                    >
                                                                    </label>
                                                                    <label onClick={() => setColor('lilla')}
                                                                        className={"btn btn-lilla " + (color === 'lilla' ? 'active' : '')}
                                                                        style={{
                                                                            borderRadius: "30px",
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            margin: 5,
                                                                        }}
                                                                        defaultChecked={color === 'lilla'}
                                                                    >
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>


                                                <div style={{
                                                    display: "flex",
                                                    alignItems: isMobile ? "baseline" : "center",
                                                    marginTop: "20px",
                                                    marginBottom: errors.name ? "0px" : "20px",
                                                    justifyContent: "center",
                                                    flexDirection: isMobile ? "column" : "row",
                                                }}>
                                                    <div
                                                        style={{
                                                            minWidth: !isMobile ? "280px" : "none"
                                                        }}> Nome evento:
                                                    </div>
                                                    <input
                                                        required
                                                        onChange={(e) => setName(e.target.value)}
                                                        type="name"
                                                        className="form-control"
                                                        defaultValue={name}
                                                        value={name}
                                                        placeholder={(categoria === "wedding" || categoria === "0") ? "Es. Il matrimonio di Giovanni e Teresa"
                                                            : categoria === "azienda" ? "Es. Festa di Natale Tim S.P.A." : categoria === "sagra-paese" ? "Palio del Ciuccio di Santa Maria la Carità" : "Es. Il 18esimo di Martina"}
                                                    />
                                                </div>
                                                {errors.name && <div style={{ color: 'red' }}>{errors.name}</div>}

                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: isMobile ? "baseline" : "center",
                                                    marginTop: "20px",
                                                    marginBottom: errors.start ? "0px" : "20px",
                                                    flexDirection: isMobile ? "column" : "row"
                                                }}>
                                                    <div
                                                        style={{
                                                            minWidth: !isMobile ? "280px" : "none",
                                                        }}>
                                                        Inserisci la data di inizio dell'evento:
                                                    </div>
                                                    <input type="date"
                                                        className="form-control"
                                                        id="dateStart" name="start"
                                                        min={new Date(Date.now() + 86400000).toISOString().split('T')[0]} // Data minima domani
                                                        value={start}
                                                        onChange={(e) => {
                                                            setStart(e.target.value);
                                                            setEnd(''); // Resetta la data di fine quando si cambia la data di inizio
                                                        }}
                                                    />
                                                </div>
                                                {errors.start && <div style={{ color: 'red' }}>{errors.start}</div>}

                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: isMobile ? "baseline" : "center",
                                                    marginTop: "20px",
                                                    marginBottom: errors.end ? "0px" : "20px",
                                                    flexDirection: isMobile ? "column" : "row"
                                                }}>
                                                    <div
                                                        style={{
                                                            minWidth: !isMobile ? "280px" : "none",
                                                        }}>
                                                        Inserisci la data di fine dell'evento:
                                                    </div>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="dateEnd"
                                                        name="end"
                                                        disabled={start === ''} // Disabilita l'input fine se la data di inizio è vuota
                                                        min={getMinEndDate()} // Data minima dipende dalla data di inizio
                                                        max={getMaxEndDate()} // Data massima (ad esempio, 5 giorni dopo la data di inizio)
                                                        value={end}
                                                        onChange={(e) => setEnd(e.target.value)}
                                                    />
                                                </div>
                                                {errors.end && <div style={{ color: 'red' }}>{errors.end}</div>}

                                                <strong>La durata massima dell'evento è di 5 giorni.</strong>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <Slider {...settings}>
                                <div className="scrren">
                                    <img
                                        src={"/assets/images/snapmemories/app/" + categoria + "/home-" + color + ".png"}
                                        alt="image" style={{
                                            border: "5px solid black",
                                            borderRadius: "15px",
                                            width: isMobile ? "25vh" : "27vh",
                                            height: isMobile ? "55vh" : "57vh"
                                        }} />
                                </div>

                                <div className="scrren">
                                    <img
                                        src={"/assets/images/snapmemories/app/" + categoria + "/griglia-" + color + ".png"}
                                        alt="image" style={{
                                            border: "5px solid black",
                                            borderRadius: "15px",
                                            width: isMobile ? "25vh" : "27vh",
                                            height: isMobile ? "55vh" : "57vh"
                                        }} />
                                </div>

                                <div className="scrren">
                                    <img
                                        src={"/assets/images/snapmemories/app/" + categoria + "/notifiche-" + color + ".png"}
                                        alt="image" style={{
                                            border: "5px solid black",
                                            borderRadius: "15px",
                                            width: isMobile ? "25vh" : "27vh",
                                            height: isMobile ? "55vh" : "57vh"
                                        }} />
                                </div>

                                <div className="scrren">
                                    <img
                                        src={"/assets/images/snapmemories/app/" + categoria + "/profilo-" + color + ".png"}
                                        alt="image" style={{
                                            border: "5px solid black",
                                            borderRadius: "15px",
                                            width: isMobile ? "25vh" : "27vh",
                                            height: isMobile ? "55vh" : "57vh"
                                        }} />
                                </div>

                                <div className="scrren">
                                    <img
                                        src={"/assets/images/snapmemories/app/" + categoria + "/descrizione-" + color + ".gif"}
                                        alt="image"
                                        style={{
                                            border: "5px solid black",
                                            borderRadius: "15px",
                                            width: isMobile ? "25vh" : "27vh",
                                            height: isMobile ? "55vh" : "57vh"
                                        }} />
                                </div>
                            </Slider>
                            <div className="row" style={{
                                paddingTop: "60px"
                            }}>
                                <div className="col-lg-5 col-3">
                                </div>
                                <div className="col-lg-2 col-6">

                                    <div className="btn_block">
                                        <div>
                                            <button onClick={() => onFinish()}
                                                className="btn puprple_btn ml-0">Continua
                                            </button>
                                            <div className="btn_bottom" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
        ;
}

export default ConfigureEvent;