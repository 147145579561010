const locale = {
    API_ENDPOINT_SERVER_MAFFLE: 'http://localhost:8081',
    API_ENDPOINT_BACKEND: 'http://localhost:8080',
    S3_BUCKET: 'snapmemories-sviluppo',
    URL_CDN: 'https://d3aqkgcgylfqcp.cloudfront.net/',
    STRIPE_CONFIG: {
        basic: 'price_1PWwrgJ5Q4L2IzvQ4THpxTmF',
        medium: 'price_1PXoHgJ5Q4L2IzvQcj7lrBHq',
        premium: 'price_1PXoI8J5Q4L2IzvQirPKKbA2',
        unico: 'price_1QSiDOJ5Q4L2IzvQ3pi5IUOv',
        locandina: 'price_1PXoAvJ5Q4L2IzvQonM7VIQ3'
    },
    LOGIN: {
        app_host: 'http://localhost:3000',
        profile_page: '/profile-page',
        cognitoConfig: {
            UserPoolId: 'eu-south-1_5rYHdjjVs',
            ClientId: '7t7uct7vucfshgf9nhvf51voa2',
            CognitoDomain: "https://snapmemories-sviluppo.auth.eu-south-1.amazoncognito.com",
            GoogleAppClientSecret: "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",
        }
    },
    PIANO: {
        basic: 8999,
        medium: 12999,
        premium: 15999,
        unico: 9999
    }
}


const sviluppo = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://12vrfdnrqg.execute-api.eu-south-1.amazonaws.com',
    API_ENDPOINT_BACKEND: 'https://z9tvazxu0j.execute-api.eu-south-1.amazonaws.com',
    S3_BUCKET: 'snapmemories-sviluppo',
    URL_CDN: 'https://d3aqkgcgylfqcp.cloudfront.net/',
    STRIPE_CONFIG: {
        basic: 'price_1PWwrgJ5Q4L2IzvQ4THpxTmF',
        medium: 'price_1PXoHgJ5Q4L2IzvQcj7lrBHq',
        premium: 'price_1PXoI8J5Q4L2IzvQirPKKbA2',
        unico: 'price_1QSiDOJ5Q4L2IzvQ3pi5IUOv',
        locandina: 'price_1PXoAvJ5Q4L2IzvQonM7VIQ3'
    },
    LOGIN: {
        app_host: 'https://snapshop-gamma.vercel.app',
        profile_page: '/profile-page',
        cognitoConfig: {
            UserPoolId: 'eu-south-1_5rYHdjjVs',
            ClientId: '7t7uct7vucfshgf9nhvf51voa2',
            CognitoDomain: "https://snapmemories-sviluppo.auth.eu-south-1.amazoncognito.com",
            GoogleAppClientSecret: "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",
        }
    },
    PIANO: {
        basic: 8999,
        medium: 12999,
        premium: 15999,
        unico: 9999
    }
};

const collaudo = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://4ne60i8enb.execute-api.eu-south-1.amazonaws.com',
    API_ENDPOINT_BACKEND: 'https://qqg71aa9og.execute-api.eu-south-1.amazonaws.com',
    S3_BUCKET: 'snapmemories-sviluppo',
    URL_CDN: 'https://d3aqkgcgylfqcp.cloudfront.net/',
    STRIPE_CONFIG: {
        basic: 'price_1QffpwQtykBPhbCv0IUgJLIf',
        medium: 'price_1QffpwQtykBPhbCv0IUgJLIf',
        premium: 'price_1QffpwQtykBPhbCv0IUgJLIf',
        unico: 'price_1QffpwQtykBPhbCv0IUgJLIf',
        locandina: 'price_1QffpwQtykBPhbCv0IUgJLIf'
    },
    LOGIN: {
        app_host: 'https://snapshop-three.vercel.app',
        profile_page: '/profile-page',
        cognitoConfig: {
            UserPoolId: 'eu-south-1_4onP79mEl',
            ClientId: '4854g8h4d9t58hlhdbsig79his',
            CognitoDomain: "https://eu-south-14onp79mel.auth.eu-south-1.amazoncognito.com",
            GoogleAppClientSecret: "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",
        }
    },
    PIANO: {
        basic: 8999,
        medium: 12999,
        premium: 15999,
        unico: 9999
    }
};

const produzione = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://0d0zxbeiee.execute-api.eu-south-1.amazonaws.com',
    API_ENDPOINT_BACKEND: 'https://in7t7a2iha.execute-api.eu-south-1.amazonaws.com',
    S3_BUCKET: 'snapmemories-produzione',
    URL_CDN: 'https://d1lyhupni6omoa.cloudfront.net/',
    STRIPE_CONFIG: {
        basic: 'price_1PflgzJ5Q4L2IzvQ9Ss4Pe4b',
        medium: 'price_1PfliCJ5Q4L2IzvQsTcreJZ0',
        premium: 'price_1PfliUJ5Q4L2IzvQ3XO2evoL',
        unico: 'price_1QSiBMJ5Q4L2IzvQjsLZ7lAH',
        locandina: 'price_1Pfls1J5Q4L2IzvQYLBmtymW'
    },
    LOGIN: {
        app_host: 'https://www.snapmemories.it',
        profile_page: '/profile-page',
        cognitoConfig: {
            UserPoolId: 'eu-south-1_9qGhnKHBJ',
            ClientId: '1je87ggttpi5s2v6ikr42vg9sk',
            CognitoDomain: "https://snapmemories.auth.eu-south-1.amazoncognito.com",
            GoogleAppClientSecret: "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"
        }
    },
    PIANO: {
        basic: 8999,
        medium: 12999,
        premium: 15999,
        unico: 9999
    }
};

const getEnv = () => {
    console.log("[ENV]: ", process.env.REACT_APP_ENV)
    switch (process.env.REACT_APP_ENV?.trim()) {
        case 'sviluppo':
            return sviluppo;
        case 'collaudo':
            return collaudo;
        case 'produzione':
            return produzione;
        default:
            return locale
    }
}

export const env = getEnv();
