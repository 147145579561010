import React, {useContext, useEffect, useState} from 'react'
import {FilePond, registerPlugin} from 'react-filepond'
import AWS from 'aws-sdk';
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import {env} from "../../configs/EnvironmentConfig"
import axios from "axios";
import {AuthContext} from "../../configs/AuthContext";


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileValidateType, FilePondPluginImagePreview)

function EventUploadManager({media, file, event, reloadEvent}) {
    const [files, setFiles] = useState([])
    const {user} = useContext(AuthContext)
    const setFilesOnChange = () => {

    }

    function formatDate(date) {
        let day = ('0' + date.getDate()).slice(-2); // Add leading zero
        let month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        let year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const handleProcessFile = async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        try {


            if (file) {

                //OTTENERE IL PRE SIGNED URL
                let formattedDate = formatDate(new Date());
                const key = 'copertina-contest/' + formattedDate + '/' + file.name
                const response = await axios.get(`${env.API_ENDPOINT_BACKEND}/snapmemories/get-pre-signed-url?key=${key}&operation=PUT&contentType=${file.type}`, {
                     headers: {
                         Authorization: `Bearer ${user.token}`,
                     },
                });
                const preSignedUrl = response.data.risultato
                await axios.put(preSignedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                    },
                });

                //MODIFICARE L'EVENTO
                await axios.post(env.API_ENDPOINT_BACKEND + "/snapmemories/modify-event", {
                    "id": event.checkoutSessionId,
                    "type": media === "image" ? "fotoProfilo" : "videoProfilo",
                    "url": preSignedUrl.split('?')[0]
                }, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                })
                reloadEvent()
                load()

            } else {
                error()
            }
        } catch (e) {
            console.log(e)
            error()
        }

    }

    useEffect(() => {
        console.log("file", file)
        if (file === '' || file == null) {
            setFiles([])
        } else {
            setFiles(file)
        }
    }, [file]);

    return (
        <div className="App" style={{fontSize: '20px'}}>
            <FilePond
                files={files}
                onupdatefiles={setFilesOnChange}
                allowMultiple={false}
                maxFiles={1}
                name="files"
                labelIdle='Drag & Drop i tuoi file o <div class="filepond--label-action">Carica da qui</div>'
                acceptedFileTypes={media === 'video' ? [
                    'video/mp4',
                    'video/quicktime',
                    'video/x-m4v',
                    'video/x-msvideo',
                    'video/x-ms-wmv',
                    'video/x-matroska',
                    'video/webm',
                    'video/ogg',
                    'video/x-flv'
                ] : [
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'image/bmp',
                    'image/webp',
                    'image/tiff',
                    'image/x-icon',
                    'image/heif',
                    'image/heic'
                ]}
                instantUpload={false}
                allowProcess={true}
                allowImagePreview={false}
                server={{process: handleProcessFile}}
            />
        </div>
    )
}

export default EventUploadManager;
