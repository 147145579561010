import React from 'react';
import { Link } from 'react-router-dom';
import team_01 from '../../assets/images/buyQr.png'
import team_03 from '../../assets/images/qrtavolo2.png'
import team_04 from '../../assets/images/enjoy.png'

function Team() {
    return (
        <>
            <section className="row_am experts_team_section">
                <div className="container">
                    <div className="section_title" >
                        <span className="title_badge">Come funziona</span>
                        <h2>
                            Segui <span>gli step</span>
                        </h2>
                    </div>
                    <div className='row' style={{justifyContent:"center"}}>
                        <div className="col-md-3">
                            <div className="experts_box">
                                <img src={team_01} alt="image" />
                                <div className="text">
                                    <h3>STEP 1 </h3>
                                    <span>Compra il codice QR con uno dei tre piani</span>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="experts_box">
                                <img loading='lazy' src={"https://snapmemories-produzione.s3.eu-south-1.amazonaws.com/foto+e+video/TereRafSubt.gif"} alt="image" />
                                <div className="text">
                                    <h3>STEP 2</h3>
                                    <span>Fai un video tutorial e organizza il tuo contest</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="experts_box">
                                <img loading='lazy' src={team_03} alt="image" />
                                <div className="text">
                                    <h3>STEP 3</h3>
                                    <span>Posiziona il QRCode su ogni tavolo della sala</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="experts_box">
                                <img loading='lazy' src={team_04} alt="image" />
                                <div className="text">
                                    <h3>STEP 4</h3>
                                    <span>Goditi il tuo evento e le foto degli invitati</span>
                                </div>
                            </div>
                        </div>
                     
                        
                    </div>
                </div>
            </section>
        </>
    );
}

export default Team;
