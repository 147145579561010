import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Home from '../components/Home/Main'
import ProfilePage from '../components/ProfilePage/Main'
import EventPage from '../components/EventPage/Main'
import SignIn from '../components/Pages/Sign In/Main'
import SignUp from '../components/Pages/Sign Up/Main'
import About from '../components/About/Main'
import Contact from '../components/Contact/Main'
import Banner from '../components/Banner/Main'
import ProductShop from "../components/ProductShop/Main";
import SuccessPage from "../components/ProductShop/SuccessPage";
import ResultPage from "../components/ProductShop/ResultPage";
import ConfirmSignUp from '../components/Pages/ConfirmSignUp/ConfirmSignUp'
import RouteGuard from '../configs/RouteGuard'
import ForgotPassword from '../components/Pages/ForgotPassword/Main'
import ResetPassword from '../components/Pages/ResetPassword/Main'
import Callback from "../components/Pages/Callback/Main"

const Routing = () => {

    return (
        <>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/come-funziona" element={<About/>}/>
                <Route path="/acquista" element={<ProductShop/>}/>
                <Route path="/result-page/success" element={<SuccessPage/>}/>
                <Route path="/result-page/error" element={<ResultPage/>}/>
                <Route path="/banner" element={<Banner/>}/>


                {/*ROTTE UTILIZZATE PER IL LOGIN*/}
                <Route path="/login" element={<SignIn/>}/>
                <Route path="/sign-in" element={<SignIn/>}/>
                <Route path="/sign-up" element={<SignUp/>}/>
                <Route path="/auth/callback" element={<Callback/>}/>
                <Route path="/confirm-sign-up" element={<ConfirmSignUp/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>

                {/*ROTTE PROTETTE*/}
                <Route
                    path="/profile-page"
                    element={
                        <RouteGuard>
                            <ProfilePage/>
                        </RouteGuard>
                    }
                />
                <Route
                    path="/profile-page/event/:id"
                    element={
                        <RouteGuard>
                            <EventPage/>
                        </RouteGuard>
                    }
                />

            </Routes>
        </>
    )
}

export default Routing