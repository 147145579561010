import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {AuthContext} from "../../configs/AuthContext";
import axios from "axios";
import {env} from "../../configs/EnvironmentConfig";
import moment from "moment";
import Loading from "../../shared-components/Loading";
import Filters from "./Filters";


function Purchases() {
    const {user} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [purchase, setPurchase] = useState([]);
    const [viewMode, setViewMode] = useState(localStorage.getItem("qrviewer") === null || localStorage.getItem("qrviewer") === "list" ? 'list' : 'grid'); // Stato per la modalità di visualizzazione
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const itemsPerPage = 9;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${env.API_ENDPOINT_SERVER_MAFFLE}/maffle/get-all-purchase?prodotto=SNAPMEMORIES`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.data.esito === "OK") {
                    setPurchase(response.data.risultato);
                } else {
                    setPurchase([]);
                }
            } catch (err) {
                setPurchase([]);
                console.error("err: ", err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user.token]);

    // Gestione della ricerca
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset della pagina quando si effettua una ricerca
    };

    // Filtra gli acquisti in base al nome
    const filteredPurchases = purchase.filter(purchase =>
        purchase?.custom?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Calcola gli indici degli elementi da visualizzare per la pagina corrente
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPurchases = filteredPurchases.slice(indexOfFirstItem, indexOfLastItem);

    // Cambia pagina
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const downloadQr = async (urlQr) => {
        try {
            const key = urlQr.split('/').slice(3).join('/');
            console.log("key",key)
            const response = await axios.get(`${env.API_ENDPOINT_BACKEND}/snapmemories/get-pre-signed-url?key=${key}&operation=GET`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const preSignedUrl = response.data.risultato
            const imageResponse = await fetch(preSignedUrl);

            const blob = await imageResponse.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'qr_code.png';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Download error:', error);
        }
    };

    if (loading) {
        return (
            <section className="purchases_section row_am">
                <div className="container">
                    <div className="section_title">
                        <h2>
                            QR Code <span>acquistati</span>
                        </h2>
                    </div>
                    <ul className="filter_tags" style={{margin: "3px 0 50px 0"}}/>
                    <Loading/>
                </div>
            </section>
        );
    }

    if (!loading && purchase.length > 0) {
        return (
            <section className="purchases_section row_am">
                <div className="container">
                    <div className="section_title">
                        <h2>
                            QR Code <span>acquistati</span>
                        </h2>
                    </div>
                    <ul className="filter_tags" style={{margin: "3px 0 25px 0"}}/>

                    {/* Filtri */}
                    <Filters setViewMode={setViewMode} onChangeSearch={handleSearch} valueSearch={searchTerm}/>


                    {viewMode === 'list' ? (
                        <div className="blog_listings">
                            {currentPurchases.map((purchase) => (
                                <div className="listing_block" key={purchase.id}>
                                    <div className="img">
                                        <img src={purchase?.custom?.qrcode} alt="image"/>
                                    </div>
                                    <div className="blog_text" style={{cursor: "pointer"}}>

                                        <div onClick={() => downloadQr(purchase?.custom?.qrcode)}
                                             className="btn puprple_btn ml-0" style={{fontSize: "20px"}}>
                                            DOWNLOAD QR CODE
                                        </div>
                                        <h2>
                                            <div style={{fontSize: "35px"}}>{purchase?.custom?.name}</div>
                                        </h2>
                                        <ul className="blog_info" style={{fontSize: "25px"}}>
                                            <li><b> Data di
                                                inizio: </b> {moment(purchase?.custom?.dateEvent?.split("T")[0]).format("DD/MM/YYYY")}
                                            </li>
                                            <li><b> Data di
                                                fine: </b> {moment(purchase?.custom?.dateEventFinish?.split("T")[0]).format("DD/MM/YYYY")}
                                            </li>
                                        </ul>
                                        <h2 style={{fontSize: "35px", marginTop: "25px", marginBottom: "25px"}}>
                                            <div><b>{purchase?.totaleSpesoConIva}€</b></div>
                                        </h2>

                                    </div>

                                    <div className="authore_info" style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between"
                                    }}>
                                        <div className="info-code">
                                            <div className="avtar">
                                                <div className="text">
                                                    <h3 style={{fontSize: "23px"}}>Piano</h3>
                                                    <span
                                                        style={{fontSize: "18px"}}>{purchase?.custom?.type?.toUpperCase()}</span>
                                                </div>
                                            </div>
                                            <div className="avtar">

                                                <div className="text">
                                                    <h3 style={{fontSize: "23px"}}>Tema</h3>
                                                    <span className={`btn btn-${purchase?.custom?.theme}`}
                                                          style={{
                                                              cursor: "none",
                                                              "width": "30px",
                                                              height: "30px",
                                                              marginTop: "5px"
                                                          }}/>
                                                </div>

                                            </div>
                                            <div className="avtar">
                                                <div className="text">
                                                    <h3 style={{fontSize: "23px"}}>Categoria</h3>
                                                    <span style={{fontSize: "18px"}}>
                                                {purchase?.custom?.categoriaEvento?.toUpperCase()}
                                            </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="handle-qr">
                                            <Link className={"custom-blog-text"}
                                                  to={'event/' + purchase?.checkoutSessionId}
                                                  style={{fontSize: "35px"}}>
                                                Gestisci <i className="icofont-arrow-right"/>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="grid_container">
                            {currentPurchases.map((purchase) => (
                                <div className="card" onClick={() => navigate('event/' + purchase?.checkoutSessionId)}
                                     key={purchase.id}>
                                    <div className="listing_block">
                                        <div style={{display: "flex", justifyContent: "space-around"}}>
                                            <div className="qr_image" style={{position: "relative", flex: "1"}}>
                                                {/* <button style={{
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "10px",
                                                    zIndex: "1",  // Assicurati che il bottone sia sopra l'immagine
                                                    padding: "10px 20px",
                                                    backgroundColor: "#007bff",  // Colore di sfondo del bottone
                                                    color: "white",  // Colore del testo del bottone
                                                    border: "none",
                                                    borderRadius: "5px",
                                                    cursor: "pointer"
                                                }}>
                                                    Bottone
                                                </button>*/}
                                                {/*<DownloadIcon
                                                    style={{
                                                        width: "15%",
                                                        height: "15%",
                                                        cursor:"pointer",
                                                        position: "absolute",
                                                        top: "10px",
                                                        right: "10px",
                                                        zIndex: "999",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                    }}
                                                />*/}
                                                <img src={purchase?.custom?.qrcode} alt="image"
                                                     style={{width: "100%"}}/>
                                            </div>
                                            <div
                                                className="grid-column"
                                                style={{
                                                    flex: "1",
                                                    padding: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-around"
                                                }}>
                                                <h2 style={{fontSize: "35px", marginTop: "5px", marginBottom: "5px"}}>
                                                    <div>
                                                        <b>{purchase?.custom?.name}</b>
                                                    </div>
                                                </h2>
                                                {/*<div style={{fontSize: "20px"}}>

                                                    {purchase?.totaleSpesoConIva}€
                                                </div>*/}

                                                <div className="grid-date"
                                                     style={{fontSize: "25px", marginTop: "5px", marginBottom: "5px"}}>
                                                    {moment(purchase?.custom?.dateEvent?.split("T")[0]).format("DD/MM/YYYY")}
                                                    <b> -> </b> {moment(purchase?.custom?.dateEventFinish?.split("T")[0]).format("DD/MM/YYYY")}
                                                </div>
                                                {/* <div style={{fontSize: "25px"}}>

                                                    <b> {purchase?.totaleSpesoConIva}€</b>
                                                </div>*/}
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-around",
                                                    alignItems: "center"
                                                }}>
                                                    {/*<div onClick={() => navigate('event/' + purchase?.checkoutSessionId)}
                                                         className="btn" style={{width: "70%",fontSize:"20px",marginTop:0}}>
                                                        GESTISCI
                                                    </div>*/}
                                                    {/*<DownloadIcon onClick={() => downloadQr(purchase?.custom?.qrcode)}
                                                                  style={{width: "23%", height: "100%",cursor:"pointer"}}/>*/}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="blog_text" style={{cursor: "pointer"}}>
                                            {/*<div style={{fontSize: "20px", marginTop: "15px", marginBottom: "15px"}}>
                                                {moment(purchase?.custom?.dateEvent?.split("T")[0]).format("DD/MM/YYYY")}
                                                <b> -> </b> {moment(purchase?.custom?.dateEventFinish?.split("T")[0]).format("DD/MM/YYYY")}
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Paginazione */}
                    <div className="pagination_block" style={{fontSize: "25px"}}>
                        <ul>
                            {Array.from({length: Math.ceil(filteredPurchases.length / itemsPerPage)}, (_, i) => (
                                <li key={i}>
                                    <Link
                                        to="#"
                                        className={currentPage === i + 1 ? "active" : ""}
                                        onClick={() => paginate(i + 1)}
                                    >
                                        {i + 1}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        );
    }

    if (!loading && purchase.length === 0) {
        return (
            <section className="purchases_section row_am">
                <div className="container">
                    <div className="section_title">
                        <h2>
                            QR Code <span>acquistati</span>
                        </h2>
                    </div>
                    <ul className="filter_tags" style={{margin: "3px 0 50px 0"}}/>
                    <section className="">
                        <div className="section_title">
                            <h2 style={{fontSize: "20px", borderBottom: "0px"}}>
                                Non hai ancora comprato nessun <span> QR CODE </span>
                            </h2>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
}

export default Purchases;
